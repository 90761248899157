import { useState } from 'react';

export const useMutation = <T>(
  mutation: (arg: T) => Promise<void>,
): { isLoading: boolean; call: (arg: T) => void } => {
  const [isLoading, setIsLoading] = useState(false);
  const call = async (arg: T) => {
    setIsLoading(true);
    await mutation(arg);
    setIsLoading(false);
  };
  return { call, isLoading };
};
