import React, { useState } from 'react';
import { useSendMessage } from '../contact/application/useSendMessage';
import { Email, Phone } from '../contact/domain/value_objects';
import { Input } from '../shared/components/inputs/Input';
import { TextArea } from '../shared/components/inputs/TextArea';
import { Layout } from '../shared/components/layout/Layout';
import { classnames } from '../shared/utils/classnames';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState(Email.empty());
  const [phone, setPhone] = useState(Phone.empty());
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { isLoading, call } = useSendMessage();

  const onSendMessage = () => {
    setHasSubmitted(true);
    if (
      email.isValid() &&
      name &&
      (!phone.value || phone.isValid()) &&
      message
    ) {
      call({ name, email: email.value, message, phone: phone.value, company });
      setName('');
      setMessage('');
      setCompany('');
      setPhone(Phone.empty());
      setEmail(Email.empty());
      setHasSubmitted(false);
    }
  };

  return (
    <Layout whiteBackground>
      <div className="w-95% md:w-2/3 flex flex-col items-center h-full mt-4">
        <h1 className="text-h1 font-bold mt-6 mb-2">CONTACTEZ NOUS</h1>
        <p className="text-small">edgard@evrox.fr</p>
        <p className="text-small">06 99 34 03 15</p>
      </div>
    </Layout>
  );
};

export default Contact;
