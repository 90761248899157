import { useMutation } from '../../shared/hooks/useMutation';
import { ContactHttpFacade } from '../infrastructure/contactHttpFacade';

export const useSendMessage = (
  facade: ContactHttpFacade = new ContactHttpFacade(),
) => {
  return useMutation(
    async (params: {
      name: string;
      email: string;
      message: string;
      phone?: string;
      company?: string;
    }) => {
      await facade.sendMessage(params);
    },
  );
};
