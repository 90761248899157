const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export class Email {
  constructor(public value: string) {}

  static empty() {
    return new Email('');
  }

  isValid() {
    return emailRegex.test(this.value);
  }
}

const phoneRegex = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/);
export class Phone {
  constructor(public value: string) {}

  static empty() {
    return new Phone('');
  }

  isValid() {
    return phoneRegex.test(this.value);
  }
}
