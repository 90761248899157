import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ServerException } from './domain/error/exceptions';
import { HttpErrorResponse, HttpService } from './domain/httpService';

enum HttpStatusCode {
  Unauthorized = 401,
  ServerError = 500,
}

export class AxiosService implements HttpService {
  protected readonly instance: AxiosInstance;

  constructor(baseURL = process.env.GATSBY_API_URL) {
    this.instance = axios.create({ baseURL });

    this.instance.interceptors.response.use(
      (response) => {
        return {
          ...response,
          response: response.data.response
            ? JSON.parse(response.data.response)
            : null,
        };
      },
      (error) => Promise.reject(error),
    );
  }

  async get(path: string) {
    try {
      return await this.instance.get(path);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async post<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.post(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async put<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.put(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async patch<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.patch(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async delete<T>(path: string, payload: T) {
    try {
      return await this.instance.delete(path, payload);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  manageErrors(e: HttpErrorResponse & Error) {
    if (e.response?.status === HttpStatusCode.Unauthorized) {
      return;
    }
    throw new ServerException(
      e?.response?.status || HttpStatusCode.ServerError,
      e?.response?.data?.message || e?.message || 'Unexpected error',
    );
  }
}
