import { AxiosService } from '../../core/AxiosService';
import { HttpService } from '../../core/domain/httpService';
import { SendMessageFailure } from '../domain';

const endpoints = {
  sendMessage: 'sendMessage',
};

const logError = (e: Error) => {
  console.log(`[ContactHttpFacade]: Failed with: ${e}`);
};

export class ContactHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async sendMessage(params: {
    name: string;
    email: string;
    message: string;
    phone?: string;
    company?: string;
  }): Promise<SendMessageFailure | null> {
    try {
      await this.httpService.post(endpoints.sendMessage, params);
      return null;
    } catch (e) {
      logError(e);
      return new SendMessageFailure(e);
    }
  }
}
